import { EventElevatorCtlGoMsg, EventWorldStateMsg, EventWorldInitMsg, MsgUnmarshalOne, EventCabinFloorMsg, EventMotorCableLengthMsg } from './messages'
import { Floor, FloorDefSVG } from './floor'
import Snap from 'snapsvg-cjs-ts';

export interface EventHanlder {
    HandleMsg(msg: any): void
}

function getRandomInt(max: number): number {
	return Math.floor(Math.random() * max);
}

const cabinSVG =
`<g id="cabin">
    <g transform="scale(40.5)">
        <path d="M48.2,15.6H19.3c-1.6,0-2.8,1.3-2.8,2.8v29c0,1.6,1.3,2.8,2.8,2.8h28.9c1.6,0,2.8-1.3,2.8-2.8V18.5   C51,17,49.7,15.6,48.2,15.6z M48.5,46.3c0,0.8-0.6,1.4-1.4,1.4H20.4c-0.8,0-1.4-0.6-1.4-1.4V19.6c0-0.8,0.6-1.4,1.4-1.4h26.7   c0.8,0,1.4,0.6,1.4,1.4V46.3z"/>
        <rect x="6.8" y="4.7" width="5.1" height="56.6"/>
        <rect x="54.1" y="4.7" width="5.1" height="56.6"/>
        <polygon points="32.2,13.5 32.2,9.5 29.4,9.5 33.7,5.5 38.1,9.5 35.3,9.5 35.3,13.5  "/>
        <polygon points="35.3,52.5 35.3,56.6 38.1,56.6 33.7,60.5 29.4,56.6 32.2,56.6 32.2,52.5  "/>
        <g>
            <circle cx="28.6" cy="21.4" r="1.9"/>
            <path d="M29.1,37.2c0-1,0-1.6,0-1.6c0-0.2-0.2-0.5-0.5-0.5s-0.5,0.2-0.5,0.5c0,0,0,0.7,0,1.7v3c0,2.7,0,5,0,5    c0,0.6-0.6,1.2-1.2,1.2s-1.2-0.5-1.2-1.2c0,0,0-2,0-4.8v-3.2c0-1.4,0-2.8,0-4.1V32c0-2.3,0-3.8,0-3.8v0.1c0-0.2-0.1-0.4-0.4-0.4    c-0.2,0-0.4,0.2-0.4,0.4c0,0,0,0.5,0,1.3v3.5c0,1,0,1.8,0,1.8c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7c0,0,0-0.4,0-1v-3.5    c0-2.3,0-4.2,0-4.2c0-1,0.8-1.9,1.9-1.9c0,0,3.3,0,5.2,0h0.3c0.2,0,0.5,0.1,0.6,0.3l2.1,2.1l2.5-2.5c0.4-0.4,1-0.4,1.3,0    c0.4,0.4,0.4,1,0,1.3l-3.2,3.2C34,28.9,33.8,29,33.5,29c-0.2,0-0.5-0.1-0.7-0.3c0,0-0.3-0.3-0.8-0.8l-0.2-0.1c0,0-0.1,0-0.2,0    c-0.2,0-0.4,0.2-0.4,0.4c0,0,0,0.6,0,1.6v10.6c0,2.7,0,4.6,0,4.6c0,0.6-0.6,1.2-1.2,1.2s-1.2-0.5-1.2-1.2c0,0,0-2,0-4.4v-3.5h0.3    V37.2z"/>
            <circle cx="42.4" cy="21" r="1.5"/>
            <circle cx="42.4" cy="24.5" r="1.5"/>
            <circle cx="42.4" cy="28.2" r="1.5"/>
        </g>
    </g>
</g>`

class Cabin {
	cabin: Snap.Element

	constructor(mainSvg: Snap.Paper) {
		let cabinSVGParsed = Snap.parse(cabinSVG)
        mainSvg.append(cabinSVGParsed.select("g"))

		this.cabin = mainSvg.select("#cabin")
	}

	HandleMsg(msg: EventMotorCableLengthMsg) { // Expects EventMotorCableLengthMsg
		this.onHeight(msg.Length)

	}

	onHeight(newHeight: number) {
		let matrix = Snap.matrix();
		// box = this.el.getBBox();
		matrix.translate(0, newHeight);
		if (this.cabin == null) {
			return
		}
		this.cabin.transform(matrix.toTransformString());
	}
}

export class MainView {
	id: number
    mainSvg: Snap.Paper;
    floors: Floor[];
    messageRouter: Map<string,EventHanlder[]>;
	cabin: Cabin

    socket: WebSocket

    constructor(mainSvg: Snap.Paper, addr: string) {
		this.id = getRandomInt(1000)
        console.log("MAIN VIEW CONSTRUCTOR")
		// this.state = 0;
		this.floors = [];
        this.mainSvg = mainSvg;

		let floorDefSVGParsed = Snap.parse(FloorDefSVG)
        let a = floorDefSVGParsed.select("g")
        mainSvg.append(a)
        a.toDefs()

		this.cabin = new Cabin(mainSvg)
		this.messageRouter = new Map();
		let that = this

		this.addMessageRouter("EventWorldState", this)
		this.addMessageRouter("EventMotorCableLength", this.cabin)

		this.socket = new WebSocket(addr);

		this.socket.onopen = function(e) {
			console.log("opening")
			let msg = new EventWorldInitMsg()
			that.socket.send(msg.Marshal())
		};

        this.socket.onmessage = function(event: MessageEvent<any>) {
			that.onWSMessage(event)
		}

		this.socket.onerror = function(error: Event) {
			console.log(`error: ${error}`)
		};
    }

	setupFloors(n: number, floorHeight: number) {
		var nextHeight = 0
		for (let i = 0; i < n; i += 1) {
            let floor = new Floor(this.mainSvg, n-(i), nextHeight)
			this.floors.push(floor);
			nextHeight += floorHeight
		}
	}

	addMessageRouter(msgName: string, handler: EventHanlder) {
		let v = this.messageRouter.get(msgName)
		if (v == null) {
			v = new Array()
		}
		v.push(handler)
		this.messageRouter.set(msgName, v)
	}

	onWSMessage(event: MessageEvent<any>) {
		let msg = MsgUnmarshalOne(event.data)
		let handlers = this.messageRouter.get(msg.Name)
		if (handlers == null) {
			// console.log(`No handler for ${msg.Name}`)
			return
		}
		handlers.forEach(function(v){
			try {
				v.HandleMsg(msg.Value);
			  } catch (error) {
				console.error(error);
				console.log(v)
			  }

		})
	}

	HandleMsg(msg: EventWorldStateMsg) { //Expects EventWorldState
		let newHeight = msg.FloorHeight*msg.Floors

		let el = this.mainSvg
		console.log("{canvas}", el)
		el.attr({viewBox: `0 0 5000 ${newHeight}`})
		console.log("{canvas}", el.attr("viewbox"))

		this.setupFloors(10, msg.FloorHeight)
	}

	GoToFloor(target: number) {
		if (this.socket.readyState != this.socket.OPEN)
		{
			console.log("SOCKET IS NOT OPEN YET")
			return
		}
		console.log("SENDING ", target)
		let msg = new EventElevatorCtlGoMsg(target)
	 	this.socket.send(msg.Marshal())
	}

	// onCallBtnClick: function() {
	// 	let v = document.getElementById("floorInput")
	// 	console.log(v.value)
	// 	this.sendElCtl(v.value)
	// },

	// sendElCtl: function(target) {
	// 	console.log(`going to ${target}`)
	// 	msg = new EventElevatorCtlGoMsg(target)
	// 	this.socket.send(msg.Marshal())
	// }
}