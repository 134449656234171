

export class EventWorldStateMsg {
	Floors: number;
	FloorHeight: number;

	constructor(Floors: number, FloorHeight: number) {
	  this.Floors = Floors
	  this.FloorHeight = FloorHeight
	}

	Marshal() {
		let buf = ""
		buf += "0;"
		buf += this.Floors + ";"
		buf += this.FloorHeight + ";"
		return buf
	}
}

export function EventWorldStateUnmarshal(data: string) {
	let res = new EventWorldStateMsg(0,0)

	let fieldsData = data.split(";", 2)
	res.Floors = parseInt(fieldsData[0])
	res.FloorHeight = parseInt(fieldsData[1])

	return res
}

export class EventCabinFloorMsg {
	Floor: number;

	constructor(Floor: number) {
	  this.Floor = Floor
	}

	Marshal() {
		let buf = ""
		buf += "1;"
		buf += this.Floor + ";"
		return buf
	}
}

export function EventCabinFloorUnmarshal(data: string) {
	let res = new EventCabinFloorMsg(0)

	let fieldsData = data.split(";", 1)
	res.Floor = parseInt(fieldsData[0])

	return res
}

export class EventMotorCableLengthMsg {
	Length: number;
	constructor(Length: number) {
	  this.Length = Length
	}

	Marshal() {
		let buf = ""
		buf += "2;"
		buf += this.Length + ";"
		return buf
	}
}

export function EventMotorCableLengthUnmarshal(data: string) {
	let res = new EventMotorCableLengthMsg(0)

	let fieldsData = data.split(";", 1)
	res.Length = parseFloat(fieldsData[0])

	return res
}

export class EventWorldInitMsg {
	constructor() {
	}

	Marshal() {
		let buf = ""
		buf += "3;"
		return buf
	}
}

export function EventWorldInitUnmarshal(data: string) {
	let res = new EventWorldInitMsg()

	let fieldsData = data.split(";", 0)

	return res
}

export class EventElevatorCtlGoMsg {
	Target: number

	constructor(Target: number) {
	  this.Target = Target
	}

	Marshal() {
		let buf = ""
		buf += "4;"
		buf += this.Target + ";"
		return buf
	}
}

export function EventElevatorCtlGoUnmarshal(data: string) {
	let res = new EventElevatorCtlGoMsg(0)

	let fieldsData = data.split(";", 1)
	res.Target = parseInt(fieldsData[0])

	return res
}

export class EventWorldDebugMsg {
	CabinFloor: number;
	CabinProximity: number;

	constructor(CabinFloor: number, CabinProximity: number) {
	  this.CabinFloor = CabinFloor
	  this.CabinProximity = CabinProximity
	}

	Marshal() {
		let buf = ""
		buf += "5;"
		buf += this.CabinFloor + ";"
		buf += this.CabinProximity + ";"
		return buf
	}
}

export function EventWorldDebugUnmarshal(data: string) {
	let res = new EventWorldDebugMsg(0, 0)

	let fieldsData = data.split(";", 2)
	res.CabinFloor = parseInt(fieldsData[0])
	res.CabinProximity = parseInt(fieldsData[1])

	return res
}


export class Message {
    Name: string;
	Value: any

	constructor(Name: string, Value: any) {
		this.Name = Name
		this.Value = Value
	}
}

export function MsgUnmarshalOne(data: string): Message {
	let firstSemiColon = data.indexOf(";")

	let msgType = data.substring(0, firstSemiColon)
	let other = data.substring(firstSemiColon + 1)

	switch (msgType) {
		case "0":
			return new Message("EventWorldState", EventWorldStateUnmarshal(other))
			break;
		case "1":
			return new Message("EventCabinFloor", EventCabinFloorUnmarshal(other))
			break;
		case "2":
			return new Message("EventMotorCableLength", EventMotorCableLengthUnmarshal(other))
			break;
		case "3":
			return new Message("EventWorldInit", EventWorldInitUnmarshal(other))
			break;
		case "4":
			return new Message("EventElevatorCtlGo", EventElevatorCtlGoUnmarshal(other))
			break;
		case "5":
			return new Message("EventWorldDebug", EventWorldDebugUnmarshal(other))
			break;
	}

	return new Message("", null)
}