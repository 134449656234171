import React, { useState } from 'react';
import './App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl'
import { Editor } from './Editor';
import { Elevator } from './Elevator';
import Button from 'react-bootstrap/Button';

function App() {
  const [targetFloorInput, setTargetFloorInput] = useState<number>(10)
  const [targetFloor, setTargetFloor] = useState<number>(10)

  function inputCh(ev: React.SyntheticEvent) {
    let v: number = +(ev.target as HTMLTextAreaElement).value
    setTargetFloorInput(v)
  }

  function actionDo() {
    setTargetFloor(targetFloorInput)
  }

  return (
    <>
      <Container fluid className="container-fluid p-3 d-flex flex-column vh-100">
        <Row>
          <Col>
            <Editor />
          </Col>
          <Col className="elevatorBg d-flex flex-column">
            <Row className='d-flex'>
              <Col>
                <Form>
                  <Form.Group  controlId="exampleForm.ControlInput1">
                    <Form.Label>Enter desired floor</Form.Label>
                    <Form.Control type="number" defaultValue={targetFloorInput} placeholder="floor" onChange={inputCh} />
                  </Form.Group>
                </Form>
              </Col>
              <Col>
                <Button variant="primary" onClick={actionDo}>Go</Button>
              </Col>

            </Row>
            <Row className='flex-grow-1'>
                <svg>
                  <Elevator className='d-block' targetFloor={targetFloor} />
                </svg>
            </Row>
          </Col>
        </Row>
      </Container>


    </>
  );
}

export default App;
