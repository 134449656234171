import { VFC, useRef, useState, useEffect } from 'react';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import styles from './Editor.module.css';
import { Config, GetConig } from './config';

async function getSources(editor: monaco.editor.IStandaloneCodeEditor) {
	try {
		const config: Config = await GetConig()
		const value: Response = await fetch(config.GetTasks())
		const textValue: string = await value.text()
		editor.setValue(textValue)
	} catch(err) {
		console.log(err)
	}
}

export const Editor: VFC = () => {
	const [editor, setEditor] = useState<monaco.editor.IStandaloneCodeEditor | null>(null);
	const monacoEl = useRef(null);

	useEffect(() => {
		if (monacoEl) {
			setEditor((editor) => {
				if (editor) return editor;

				let o = monaco.editor.create(monacoEl.current!, {
					value: ['function x() {', '\tconsole.log("Hello world!");', '}'].join('\n'),
					language: 'typescript'
				})

				getSources(o)
				return o
			});
		}

		return () => editor?.dispose();
	}, [monacoEl.current]);

	return <div className={styles.Editor} ref={monacoEl}></div>;
};