import './Elevator.css'
import { useRef, useState, useEffect } from 'react';
import Snap from 'snapsvg-cjs-ts';
import { MainView } from './simulator/mainsvg';
import {GetConig, Config} from './config'

const initialSvg = `
`

interface FullName {
    className: string;
    targetFloor: number;
}

export const Elevator = (props: FullName) => {
    const [ mv, setMV ] = useState<MainView>()

    if (mv != null) {
        console.log("GOGO ", props.targetFloor)
        mv.GoToFloor(props.targetFloor)
    }

	// const [editor, setEditor] = useState<monaco.editor.IStandaloneCodeEditor | null>(null);
	useEffect(() => {
        async function buildMainView() {
            const config: Config = await GetConig()
            let mainSvg = Snap("#mainsvg")
            setMV(new MainView(mainSvg, config.GetChannel()))
        }

        console.log("use effect", mv)
        if (mv != null) {
            return
        }

        buildMainView()

        return () => {
            let mainSvg = Snap("#mainsvg")
            mainSvg.children().forEach( child => {
                child.remove()
            })
        }
	}, []);

	return <svg id="mainsvg" className={props.className}>

        {/* <defs>
            <g id="floorDef">
                <rect x="500" y="0" width="1100" height="2300" className='floorRect' ></rect>
            </g>
        </defs> */}



    </svg>;
};