import Snap from 'snapsvg-cjs-ts';

export const FloorDefSVG =
`<g id="floorDef">
    <rect width="1100" height="2300" class='floorRect' ></rect>
</g>`

export function FloorDef(snapEl: Snap.Paper) {

}

export class Floor {
	constructor(snapEl: Snap.Paper, floorN: number, floorHeight: number) {

		let localG = snapEl.g()
        let koo = localG.use("floorDef")
        let t = localG.text(200, 250, floorN)
        t.attr({class: "floorText", "dominant-baseline": "hanging", "text-anchor": "end"})

		let matrix = Snap.matrix();
		matrix.translate(0, floorHeight);
		localG.transform(matrix.toTransformString());
	}
}