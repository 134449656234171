export interface ConfigDTO {
    BackendAddr: string;
    tls: boolean;
}

export class Config {
    dto: ConfigDTO

    constructor(dto: ConfigDTO) {
        this.dto = dto
    }

    GetChannel(): string {
        if (this.dto.tls) {
            return "wss://" + this.dto.BackendAddr + "/echo"
        }

        return "ws://" + this.dto.BackendAddr + "/echo"
    }

    GetTasks(): string {
        if (this.dto.tls) {
            return "https://" + this.dto.BackendAddr + "/tasks"
        }

        return "http://" + this.dto.BackendAddr + "/tasks"
    }
}

let _init = false
let _cfg: Config

export async function GetConig(): Promise<Config> {
    if (_init) {
        console.log("Config already inited")
        return _cfg
    }

    console.log("Config is being fetched")
    const response = await fetch("/config.json");
    const dto: ConfigDTO = await response.json()
    _cfg = new Config(dto)
    _init = true
    return _cfg
}
